<template>
  <div class="product__detail">
    <div class="product__detail__main">
      <div class="product__detail__section">
        <ProductPhoto @photos="addPhotos" />
      </div>
      <div class="product__detail__section">
        <ProductDesc @desc="addDesc" />
      </div>
    </div>

    <button
      class="product__detail__create"
      v-if="photo && desc"
      @click="createNewProduct"
    >
      Crear producto
    </button>

    <Loader v-if="loading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    ProductPhoto: () => import("../components/product/productPhoto.vue"),
    ProductDesc: () => import("../components/product/product_desc.vue"),
    Loader: () => import("../components/loader.vue"),
  },

  data() {
    return {
      productPhotos: [],
      productDescription: {},
      photo: false,
      desc: false,
      loading: false,
    };
  },

  methods: {
    addPhotos(photos) {
      this.productPhotos = photos;
      this.photo = true;
    },
    addDesc(desc) {
      this.productDescription = desc;
      if(this.productDescription.specifications.length == 0) {
        this.productDescription.specifications = "";
      }
      this.desc = true;
    },

    async createNewProduct() {
      const newProduct = {
        ...this.productDescription,
      };
      this.loading = true;
      try {
        await this.createProduct({
          product: newProduct,
          photos: this.productPhotos,
        });
      } catch (error) {
        this.$toast.error("Ocurrio un error al crear el producto");
      }
      finally {
        this.$router.push({ name: 'Dashboard' })
      }
      this.loading = false;
    },

    ...mapActions("webStore", ["createProduct"]),
  },
};
</script>
