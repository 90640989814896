<template>
    <div class="dash_container">
        <dheader></dheader>
        <div class="show_nav_container">
            <img src="../../assets/img/menu.jpg" alt="" srcset="" @click="showNav()">
        </div>
        <div class="dash_nav_container" id="nav">
            <div class="dash_nav_component">
                <img src="https://firebasestorage.googleapis.com/v0/b/di-medical-del-sur.appspot.com/o/static%2FlogoCorporativo.png?alt=media&token=ca32a756-7656-4259-b5b7-921c11a0a3e8" alt="" srcset="" id="logo" width="200px">
            </div>
            <!-- <div class="dash_nav_component tablinks active" id="Charts" @click="openContent($event, 'Charts', 'Dashboard')">
                <p>Dashboard</p>
                <img src="../../assets/img/iconHome.jpg"  alt="" srcset="">
            </div> -->
            <div class="dash_nav_component tablinks" id="vCard" @click="openContent($event, 'vCard', 'vCard')">
                <p>Vcard</p>
                 <img src="../../assets/img/vCard.jpg"  alt="" srcset="">
            </div>
             <div class="dash_nav_component tablinks" id="Posts" @click="openContent($event, 'Posts', 'Posts')">
                <p>Post</p>
                <img src="../../assets/img/post.jpg" alt="" srcset="">
            </div>
            <div class="dash_nav_component tablinks" id="stories" @click="openContent($event, 'WebPage', 'Web page')">
                <p>Web page</p>
                <img src="../../assets/img/historia.jpg" alt="" srcset="">
            </div>
        </div>        
    
        <div class="content">
            <transition name="fade" mode="out-in">
                <keep-alive>
                    <component :is="tab"></component>
                </keep-alive>
            </transition>

        </div>
    </div>
</template>

<script>
const Dheader = () => import(/* webpackChunkName: "Header" */'./Header/header.vue');
const vCard = () => import(/* webpackChunkName: "vCard" */'./vCard/vCard.vue');
const Posts = () => import(/* webpackChunkName: "Posts" */'./Posts/posts.vue');
const WebPage = () => import(/* webpackChunkName: "stories" */'./WebPage/webPage.vue');

export default {
    name:"Dashboard",
    components:{
        Dheader,
        vCard,
        Posts,
        WebPage,
    },
    data(){
        return{
            nav:true,
            tab: "WebPage"
      }
    },

    mounted(){
        this.queryParams();
        this.tab = 'vCard'
        
    },

    methods:{
        
        openContent(evt, content, title){
            this.tab = content;
            let tablinks = document.getElementsByClassName("tablinks");
            for (let i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.title = title;
            evt.currentTarget.className += " active";

        },

        showNav(){
        let navCom = document.getElementById("nav");
            if(navCom.style.left == "-1500px"){
                navCom.style.left = "0";
            } else {
                navCom.style.left = "-1500px";
            } 
        },

        queryParams(){
            let urlParams = new URLSearchParams(window.location.search);
            let urlContent = urlParams.get("content");

            if(urlContent){
                let tablinks = document.getElementsByClassName("tablinks");
                for (let i = 0; i < tablinks.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" active", "");
                }
                document.title = urlContent;
                this.tab = urlContent;
                document.getElementById(urlContent).className += " active";
            } else {
                this.tab = "Charts";
                document.title = "Charts";
            }
        }
    },
}
</script>
