<template>
  <div class="product__detail">
    <div class="product__detail__main">
      <div class="product__detail__section">
        <ProductPhoto @photos="addPhotos" />
      </div>
      <div class="product__detail__section">
        <ProductDesc @desc="addDesc" :productInfo="productToUpdate" />
      </div>
    </div>

    <button
      class="product__detail__create"
      v-if="desc"
      @click="updateCurrentProduct"
    >
      Actualizar producto
    </button>

    <Loader v-if="loading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    ProductPhoto: () => import("../components/product/productPhoto.vue"),
    ProductDesc: () => import("../components/product/product_desc.vue"),
    Loader: () => import("../components/loader.vue"),
  },

  data() {
    return {
      productPhotos: [],
      productDescription: {},
      desc: false,
      loading: false,
      productToUpdate: {}
    };
  },

  created() {
    this.getProductInfo()
  },

  methods: {
    addPhotos(photos) {
      this.productPhotos = photos;
      this.photo = true;
      console.log(this.productPhotos);
    },
    addDesc(desc) {
      this.productDescription = desc;
      this.desc = true;
    },

    getProductInfo(){
      const product = this.$store.getters['webStore/getProductById'](this.$route.params.id)
      if(!product) {
        this.$router.push({ name: 'Dashboard'})
        return;
      }
      this.productToUpdate = product
    },

    async updateCurrentProduct() {
      const newProduct = {
        ...this.productDescription,
      };
      this.loading = true;
      try {
        await this.updateProduct({
          product: newProduct,
          photos: this.productPhotos,
          id: this.$route.params.id
        });
        this.$toast.success("Se actualizo correctamente el producto");
      } catch (error) {
        this.$toast.error("Ocurrio un error al crear el producto");
      }
      finally {
        this.$router.push({ name: 'Dashboard' })
      }
      this.loading = false;
    },

    ...mapActions("webStore", ["updateProduct"]),
  },
};
</script>